.ant-table-content {
    overflow-x: scroll;
}

.ant-table-row-primary {
    background-color: antiquewhite;
}

.ant-form-item-children .ant-input-number {
    width: 100%;
}
